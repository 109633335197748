<template>
  <div class="about">
    <div class="menuVoice voiceHome">
      <a href="/"
        >VALENTINA <br />
        FURIAN</a
      >
    </div>
    <div class="about__container" v-if="loading">
      <div class="about__text about__text--first">
        <p
          v-for="(par, index) in about.about_first"
          :key="'first:'+index"
          v-html="par.text"
        ></p>
      </div>
      <div class="about__text about__text--second">
        <p
          v-for="(par, index)  in about.about_second"
          :key="'second'+index"
          v-html="par.text"
        ></p>
      </div>
      <div class="about__text about__text--contact">
        <h5 class="contact__title">CONTACT</h5>
        <p v-for="par in about.contact" :key="par.text" v-html="par.text"></p>
        <p class="contact__email" v-html="about.email"></p>
        <div class="social__container">
          <a v-if="about.cv.url" :href="about.cv.url" target="blank">
            <div class="social__button" id="fb">CV</div>
          </a>  
          <a v-if="about.facebook.link_type !== 'Any'" :href="about.facebook" target="blank">
            <div class="social__button" id="fb">FB</div>
          </a>
          <a v-if="about.instagram.link_type !== 'Any'" :href="about.instagram" target="blank">
            <div class="social__button">IG</div>
          </a>
        </div>
        <div class="social__container--texts">
          <a v-if="about.critica.url" :href="about.critica.url" target="blank">
            <div class="social__button" id="fb">TEXTS</div>
          </a>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Initialize "response"
      about: null,
      loading: false
    };
  },
  methods: {
    async getContent() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.getSingle("about");
      this.about = response.data;
      this.loading = true
      // console.log(this.about);
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
.about {
  background: var(--main-color);
  min-height: 100vh;
  padding-top: 20px;

    @media screen and (max-width: 768px) {
    padding-bottom: 50px;
  }
}
a {
  text-decoration: none;
}

.menuVoice {
  width: 100%;
  font-size: 14vh;
  color: black;
  text-align: center;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: normal;
  a {
    color: black;
    text-decoration: none;
    &:hover {
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 12vw
  }
}
.menulist {
  padding-top: 6vh;
}
.voice {
  text-decoration: none;
  &:hover {
    background: var(--main-color);
    color: black;
  }
}

.voiceHome {
  font-weight: bold;
  font-size: 30px;
  // padding-bottom: 15vh;
  letter-spacing: 2px;
}

.about__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 3vw;
  margin: 8vw 50px;
  @media screen and (max-width: 768px) {
    margin: 18vw 20px;
      grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  }
  p,
  .contact__title,
  .contact__email {
    font-size: 16px;
    font-weight: bold0;
    line-height: 160%;
    letter-spacing: 0.01rem;
    font-family: Nukle, Helvetica, Arial, sans-serif;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .contact__title {
    margin-top: 16px;
  }

  .contact__email {
    text-decoration: underline;
  }
}

.social__container {
  display: flex;
  margin-top: 3rem;

  &--texts{
    display: inline-block;
    margin-top: -1px;
  }
}

.social__button {
  color: black;
  font-size: 18px;
  font-family: Nukle, Helvetica, Arial, sans-serif;
  padding: 8px 24px;
  border: 1px solid black;
  border-right: none;

  &:hover {
    background: black;
    color: var(--main-color);
  }
  &:last-child{
    border-right: 1px solid black;
  }

}

//  #fb{
//     border-right: none;
//   }
</style>
